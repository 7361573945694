import * as React from 'react';

import {Rep} from '../users/user.types';
import {User} from '../users/user.class';

export interface MyAccountWidgetProps {
    reps: Rep[];
    user: User;
}

export const MyAccountWidget = ({reps, user}: MyAccountWidgetProps) => (
    <div
        className="tw-hidden md:tw-block tw-h-full"
        id="myAccountWidget"
    >
        <div className="tw-h-full tw-flex tw-flex-col">
            {user.isLoggedIn() && user.divisionNumber === `S6N` && (
                <div className="tw-border tw-border-t-4 tw-text-center tw-p-3">
                    <img
                        alt="Ultimate Shine Car Wash Logo"
                        src="/logos/ultimate-logo.png"
                    />
                </div>
            )}
            {user.isLoggedIn() && user.divisionNumber === `S7N` && (
                <div className="tw-border tw-border-t-4 tw-text-center tw-p-3">
                    <img
                        alt="Flagship Car Wash Logo"
                        src="/logos/flag-ship.jpg"
                    />
                </div>
            )}
            {user.isLoggedIn() && user.divisionNumber === `S8N` && (
                <div className="tw-border tw-border-t-4 tw-text-center tw-p-3">
                    <img
                        alt="Cobblestone Auto Spa Logo"
                        src="/logos/cobblestone-logo.png"
                    />
                </div>
            )}
            {user.isLoggedIn() && user.divisionNumber === `S9N` && (
                <div className="tw-border tw-border-t-4 tw-text-center tw-p-3">
                    <img
                        alt="Okie Auto Wash Logo"
                        src="/logos/okie-logo.png"
                    />
                </div>
            )}
            {((reps && reps.length > 0) || user.fullName) && (
                <>
                    <div className={`tw-h-full tw-border tw-border-t-4`}>
                        <div className="tw-p-3 tw-capitalize">
                            <h5 className="maskPII">{user.fullName?.toLowerCase()}</h5>
                            <div className="tw-pb-2">
                                <div className="maskPII">{user.city?.toLowerCase()}</div>
                                <div className="maskPII">{user.coName?.toLowerCase()}</div>
                                <div>{user.activeAccount ? `#${user.activeAccount} ` : ''}</div>
                            </div>
                            <a href="/account">My Account</a>
                        </div>
                    </div>
                    {reps?.length > 0 && (
                        <div className="tw-border tw-border-t-4 tw-h-full tw-p-3">
                            <h5>{user.departmentNumber === `26` ? 'Customer Service' : 'Dedicated Account Advisor'}</h5>
                            {reps.map((rep, index) => (
                                <React.Fragment key={index}>
                                    <div className="tw-pb-2 maskPII">
                                        <div>{rep.name}</div>
                                        <div>
                                            <span suppressHydrationWarning>Phone: 1-800-558-2808</span>
                                            {rep.extension && <span className="tw-whitespace-nowrap"> ext.{rep.extension}</span>}
                                        </div>
                                        {rep.extension && (
                                            <div>
                                                Fax: <span suppressHydrationWarning>{rep.fax}</span>
                                            </div>
                                        )}
                                    </div>
                                    {rep.mailto && <a href={`mailto:${rep.mailto}`}>Send Email</a>}
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    </div>
);
