export const convertButtonTypeToVariant = (button_type: string) => {
    switch (button_type) {
        case 'btn-red':
        case 'red':
            return 'primary';
        case 'btn-outline-secondary':
        case 'outline-secondary':
            return 'outline-secondary';
        case 'btn-secondary':
        case 'secondary':
            return 'secondary';
        case 'btn-primary':
        case 'primary':
            return 'blue';
        case 'btn-link':
        case 'link':
            return 'link';
        case 'btn-outline-primary':
        case 'outline-primary':
            return 'outline-blue';
        case 'btn-outline-red':
        case 'outline-red':
            return 'outline-primary';
        case 'btn-light':
        case 'light':
            return 'light';
    }
};
