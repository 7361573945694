import * as React from 'react';
import {Corporation, WebSite, WithContext} from 'schema-dts';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useEffect, useMemo, useState} from 'react';

import {Alerts} from '../ui/Alerts/Alerts';
import {BannerAd} from '../ads/campaign-ads/campaign-ads.class';
import {CatalogCategoriesWidget} from './CatalogCategoriesWidget';
import {CMSListWidget} from './CMSListWidget';
import {ContactTiles} from './ContactTiles';
import {CorpAllowanceWidget} from './CorpAllowanceWidget';
import {CorpAllowance} from '../promos/promo.class';
import {GridListWidget} from './GridListWidget';
import {HomeCategoriesWidget} from './HomeCategoriesWidget';
import {HomePageContent} from '../cms/cms.types';
import {HomeRecoSpinner} from './HomeRecoSpinner';
import {HomeZone1, HomeZone2, HomeZone3} from '../ads/ads.types';
import {HomeZoneOne} from './HomeZoneOne';
import {HomeZoneThree} from './HomeZoneThree';
import {HomeZoneTwo} from './HomeZoneTwo';
import {ListsWidget} from './ListsWidget';
import {LoggedOutSections} from './LoggedOutSections';
import {MobileTiles} from './MobileTiles';
import {MonetateService} from '../../client/monetate/monetate.service';
import {MyAccountWidget} from './MyAccountWidget';
import {OrderHeader} from '../orders/order-header.class';
import {OrderItemsWorkflow} from '../../client/order-items/order-items.workflow';
import {OrdersWidget} from './OrdersWidget';
import {PrevPurchasedSpinner} from './PrevPurchasedSpinner';
import {Rep} from '../users/user.types';
import {SafetyGlassForm} from './SafetyGlassForm';
import {ScannerLogic} from '../scanner/ScannerLogic';
import {ShippingSaverWidget} from './ShippingSaverWidget';
import {UserAgent} from '../../client/user-agent/user-agent.class';
import {UserList, WidgetCMSLists} from '../lists/lists/lists.class';
import {UserStateService} from '../../client/users/user-state.service';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';
import {WebcatMeta} from '../items/item.class';

const corpSchema: WithContext<Corporation> = {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'Imperial Supplies',
    description:
        'Founded in 1958, Imperial Supplies is the leader in heavy duty truck parts and fleet maintenance supplies. See how our supply solutions can fit your exact needs.',
    logo: 'https://www.imperialsupplies.com/dimg/imperial_logo_rgb.svg',
    url: 'https://www.imperialsupplies.com/',
    telephone: '1-800-558-2808',
    faxNumber: '1-800-553-8769',
    founder: 'Don Long Sr.',
    parentOrganization: 'W. W. Grainger',
    sameAs: [
        'https://twitter.com/#!/Imperial_Tweets',
        'https://www.linkedin.com/company/imperial-supplies',
        'https://www.facebook.com/pages/Imperial-Supplies/160873310666103',
        'https://www.youtube.com/user/ImperialSupplies',
    ],
    address: {
        '@type': 'PostalAddress',
        streetAddress: '300 North Madison Street',
        addressLocality: 'Green Bay, WI',
        postalCode: '54307-1008',
    },
};

const siteSchema: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Imperial Supplies LLC',
    url: 'https://www.imperialsupplies.com/',
    potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.imperialsupplies.com/search?&SearchString={search_term_string}',
        // @ts-ignore
        'query-input': 'required name=search_term_string',
    },
};
const componentName = `home-component`;

export interface HomePageProps {
    bannerAd?: BannerAd;
    corpAllowance: CorpAllowance;
    getListsErr: string;
    getOrdersErr: string;
    homePageContent: HomePageContent;
    homeZone1?: HomeZone1;
    homeZone2?: HomeZone2;
    homeZone3?: HomeZone3;
    orderHeaders: OrderHeader[];
    reps: Rep[];
    showWidgets: boolean;
    userData: User;
    widgetCMSCategories: WebcatMeta[];
    widgetCMSCategoriesMarket: string;
    widgetCMSCategoriesName: string;
    widgetCMSCategoriesTitle: string;
    widgetCMSLists: WidgetCMSLists;
    widgetLists: UserList[];
    helmetContext?: any;
}

export const HomePage = ({
    bannerAd,
    corpAllowance,
    getListsErr,
    getOrdersErr,
    helmetContext,
    homePageContent,
    homeZone1,
    homeZone2,
    homeZone3,
    orderHeaders,
    reps,
    showWidgets,
    userData,
    widgetCMSCategories,
    widgetCMSCategoriesMarket,
    widgetCMSCategoriesName,
    widgetCMSCategoriesTitle,
    widgetCMSLists,
    widgetLists,
}: HomePageProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const [scanErrorListState, setScanErrorListState] = useState<string[]>();
    const [successMessage, setSuccessMessage] = useState(``);
    const monetateService: MonetateService = useService(`monetateService`);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const user = useMemo(() => new User(userData), [userData]);
    const userStateService: UserStateService = useService(`userStateService`);

    // handle home analytics
    useEffect(() => {
        monetateService.setPageType(`home`);
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(componentName)) {
            orderItemsWorkflow.addToOrderModalObservable(userStateService.addToOrderItems, componentName, true).subscribe({
                next: () => {
                    clearOfflineItems();
                },
                error: () => {
                    // Error silently
                },
            });
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Instructs native app to clear offline items
     */
    const clearOfflineItems = () => {
        if (UserAgent.isReactNative()) {
            UserAgent.postNativeMessage({
                event: `offlineItemsUploadedSuccess`,
            });
        }
    };

    /**
     * Template
     */
    return (
        <HelmetProvider context={helmetContext}>
            <div
                className={`${user.isLoggedIn() ? 'tw-bg-gray-100 md:tw-bg-white ' : ''}-tw-m-4 -tw-mt-6 -tw-mb-10 tw-p-4`}
                data-e2e="home-component"
            >
                <Alerts
                    message={successMessage}
                    variant="success"
                />
                <Alerts
                    message={errorMessage}
                    variant="danger"
                />
                {scanErrorListState?.length > 0 &&
                    scanErrorListState.map((scanError, index) => (
                        <Alerts
                            key={index}
                            message={scanError}
                            variant="danger"
                        />
                    ))}
                {showWidgets && (
                    <>
                        <div className="tw-block md:tw-hidden">
                            <div className="mb-3">
                                Welcome, <span className="maskPII tw-capitalize">{user.fullName?.toLowerCase()}</span>
                            </div>
                            <MobileTiles user={user} />
                        </div>
                        <div className="md:tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 md:tw-mb-6">
                            <MyAccountWidget
                                reps={reps}
                                user={user}
                            />
                            <OrdersWidget
                                getOrdersErr={getOrdersErr}
                                orderHeaders={orderHeaders}
                            />
                            {widgetCMSLists && widgetCMSLists.layout_type === `List View` && widgetCMSLists.lists.length > 0 ? (
                                <CMSListWidget
                                    getListsErr={getListsErr}
                                    widgetCMSLists={widgetCMSLists}
                                />
                            ) : (
                                <ListsWidget
                                    getListsErr={getListsErr}
                                    widgetLists={widgetLists}
                                />
                            )}
                            {corpAllowance ? (
                                <CorpAllowanceWidget corpAllowance={corpAllowance} />
                            ) : (
                                <HomeZoneThree
                                    homePageContent={homePageContent}
                                    homeZone3={homeZone3}
                                />
                            )}
                        </div>
                    </>
                )}
                {user.isLoggedIn() && <GridListWidget widgetCMSLists={widgetCMSLists} />}
                {user.isLoggedIn() && widgetCMSCategories?.length > 0 && (
                    <HomeCategoriesWidget
                        mobileOnly
                        widgetCMSCategories={widgetCMSCategories}
                        widgetCMSCategoriesMarket={widgetCMSCategoriesMarket}
                        widgetCMSCategoriesName={widgetCMSCategoriesName}
                        widgetCMSCategoriesTitle={widgetCMSCategoriesTitle}
                    />
                )}
                {user.isLoggedIn() && (
                    <div className="tw-block md:tw-hidden tw-mb-2">
                        <ContactTiles reps={reps} />
                    </div>
                )}
                <HomeZoneOne
                    bannerAd={bannerAd}
                    homeZone1={homeZone1}
                />
                <HomeZoneTwo homeZone2={homeZone2} />
                {user.isLoggedIn() && user.hasCustomCatalogs() && corpAllowance && <CatalogCategoriesWidget />}
                {user.isLoggedIn() && widgetCMSCategories?.length > 0 && (
                    <HomeCategoriesWidget
                        desktopOnly
                        widgetCMSCategories={widgetCMSCategories}
                        widgetCMSCategoriesMarket={widgetCMSCategoriesMarket}
                        widgetCMSCategoriesName={widgetCMSCategoriesName}
                        widgetCMSCategoriesTitle={widgetCMSCategoriesTitle}
                    />
                )}
                {user.isLoggedIn() ? (
                    <>
                        {user.activeParent === `848` && <SafetyGlassForm user={user} />}
                        {user.hasRecentOrders() && (
                            <div className="imp-item-spinner-dark tw-p-4 md:tw-p-0 tw-mb-4 md:tw-mb-6 tw-bg-white tw-mt-4 md:tw-mt-0">
                                <PrevPurchasedSpinner user={user} />
                            </div>
                        )}
                        {user.canViewPrice() && user.isAccountLevel() && user.showShipSaverWidget() && <ShippingSaverWidget user={user} />}
                        <HomeRecoSpinner user={user} />
                    </>
                ) : (
                    <LoggedOutSections homePageContent={homePageContent} />
                )}
                <ScannerLogic
                    componentName={componentName}
                    onSubmitObsoleteItems={() => {
                        setErrorMessage(``);
                        setSuccessMessage(``);
                    }}
                    onSubmitObsoleteItemsErr={(submitObsoleteItemsErr) => setErrorMessage(submitObsoleteItemsErr.message)}
                    onSubmitObsoleteItemsRes={(scanErrorList) => {
                        setSuccessMessage(`Items submitted successfully`);
                        if (scanErrorList) {
                            setScanErrorListState(scanErrorList);
                        }
                    }}
                    onUploadOfflineItemsRes={clearOfflineItems}
                />
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify(corpSchema)}</script>
                </Helmet>
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify(siteSchema)}</script>
                </Helmet>
            </div>
        </HelmetProvider>
    );
};
