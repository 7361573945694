import * as React from 'react';

import {faFilePdf} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {User} from '../users/user.class';
import {CollapseZone} from './CollapseZone';
import Button from '../ui/Buttons/Button';

export interface SafetyGlassFormProps {
    user: User;
}

export const SafetyGlassForm = ({user}: SafetyGlassFormProps) => {
    return (
        <div className="tw-mb-2">
            <CollapseZone title="Prescription Safety Glasses">
                <table className="tw-border tw-w-full">
                    <tbody>
                        <tr>
                            <td className="tw-border tw-p-2 md:tw-p-6">
                                <img
                                    alt="Start Order"
                                    className="tw-max-w-full tw-h-auto"
                                    src="/fedex_assets/hoya-safety-division.jpg"
                                />
                            </td>
                            <td className="tw-pt-3 tw-pl-4">
                                <p className="tw-text-xl tw-mb-2 tw-font-normal">
                                    Hoya Safety Division is Imperial's Partner for Prescription Safety Glasses
                                </p>
                                <p>
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        size="2x"
                                        className="tw-mr-2"
                                        suppressHydrationWarning
                                    />
                                    <a
                                        href="/pdf/FedEx-Prescription-Safety-Glasses-Ordering-Instructions.pdf"
                                        target="_blank"
                                    >
                                        Ordering Instructions
                                    </a>
                                    <br />
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        size="2x"
                                        className="tw-mr-2"
                                        suppressHydrationWarning
                                    />
                                    <a
                                        href="/pdf/FedEx-Prescription-Safety-Glasses-Rules.pdf"
                                        target="_blank"
                                    >
                                        Safety Eyewear Program Details and Rules
                                    </a>
                                </p>
                            </td>
                            <td className="tw-p-4">
                                <form
                                    action="https://us.hoyasafety.com/login.asp?USER=fedex_adm&PWD=55a0ea1afef0a79462e29df7d7672534"
                                    method="post"
                                    name="submitform"
                                    target="_blank"
                                >
                                    <input
                                        type="hidden"
                                        name="username"
                                        value="fedex_adm"
                                    />
                                    <input
                                        type="hidden"
                                        name="passwd"
                                        value="55a0ea1afef0a79462e29df7d7672534"
                                    />
                                    <input
                                        type="hidden"
                                        name="email"
                                        value={user.loginName}
                                    />
                                    <input
                                        type="hidden"
                                        name="name"
                                        value={user.fullName}
                                    />
                                    <input
                                        type="hidden"
                                        name="account"
                                        value={user.activeAccount}
                                    />
                                    <input
                                        type="hidden"
                                        name="badge"
                                        value={user.loginName.substring(0, user.loginName.indexOf('@'))}
                                    />
                                    <input
                                        type="hidden"
                                        name="szip"
                                        value={user.zip}
                                    />
                                    <Button
                                        className="!tw-w-fit tw-whitespace-nowrap"
                                        type="submit"
                                        size="sm"
                                        variant="secondary"
                                    >
                                        Order Now
                                    </Button>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </CollapseZone>
        </div>
    );
};
