import * as React from 'react';
import {useEffect, useState} from 'react';

import {CollapseZone} from './CollapseZone';
import {ImpError} from '../../client/imp-error/imp-error.class';
import {ItemsService} from '../../client/items/items.service';
import {useService} from '../react/ServiceContext';
import {WebcatMeta} from '../items/item.class';
import Button from '../ui/Buttons/Button';

export interface CatalogCategoriesProps {
    desktopOnly?: boolean;
    mobileOnly?: boolean;
}

export const CatalogCategoriesWidget = ({desktopOnly, mobileOnly}: CatalogCategoriesProps) => {
    const [productCategories, setProductCategories] = useState<WebcatMeta[]>([]);
    const itemsService: ItemsService = useService(`itemsService`);

    // Get root categories to display
    useEffect(() => {
        itemsService
            .getRootCategories()
            .then((getRootCategoriesRes) => {
                setProductCategories(getRootCategoriesRes);
            })
            .catch((getRootCategoriesErr: ImpError) => {
                // Error silently
                // eslint-disable-next-line no-console
                console.log(getRootCategoriesErr.message);
            });
        // We don't want itemsService to trigger changes in this hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CollapseZone
            desktopOnly={desktopOnly}
            mobileOnly={mobileOnly}
            title="Categories"
            titleLink={
                <Button
                    className="!tw-px-4"
                    size="sm"
                    variant="link"
                    href="/browse"
                >
                    See All
                </Button>
            }
        >
            <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-6 tw-gap-4 md:tw-min-h-[614px] lg:tw-min-h-[428px]">
                {productCategories.map((cat, index) => {
                    if (index < 12) {
                        return (
                            <div
                                className="tw-border tw-h-full tw-p-3"
                                key={index}
                            >
                                <a
                                    className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center"
                                    href={`/browse/${cat.categoryPath}`}
                                >
                                    <img
                                        alt={cat.name}
                                        className="tw-max-w-full tw-h-auto tw-my-3"
                                        height="120"
                                        src={`/ProductImageThumbs240${cat.imagePath || '/noimage.png'}`}
                                        width="120"
                                    />
                                    <div>{cat.name}</div>
                                </a>
                            </div>
                        );
                    }
                })}
            </div>
        </CollapseZone>
    );
};
